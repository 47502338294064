import {Carousel} from '3d-react-carousal';

import Banner1 from '../../assets/images/img1.png'
import Banner2 from '../../assets/images/img2.jpg'
import Banner3 from '../../assets/images/img3.jpg'
import Banner4 from '../../assets/images/img4.jpg'
import Banner5 from '../../assets/images/img5.jpg'
import Banner6 from '../../assets/images/img6.jpg'
import Banner7 from '../../assets/images/img7.jpg'
import Banner8 from '../../assets/images/img8.jpg'

import './index.css';

const Gallery = () => {
  let slides = [
    <img src={Banner1} alt="1" />,
    <img src={Banner2} alt="2" />,
    <img src={Banner3} alt="3" />,
    <img src={Banner4} alt="4" />,
    <img src={Banner5} alt="5" />,
    <img src={Banner6} alt="6" />,
    <img src={Banner7} alt="7" />,
    <img src={Banner8} alt="8" />
  ];
  return (
    <section className="section-gallery">
      <div className="u-sheet">
        <h1 className="u-title">Our Work</h1>
        <div className="App">
          <Carousel slides={slides} />
        </div>
      </div>
    </section>
  );
}

export default Gallery;