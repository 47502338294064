import './index.css';
import Img from '../../assets/images/logo.png'

const Logo = (props) => {
  return (
    <div className="logo">
      <div className="logo-wrap">
        <a href="http://pace.style" className="u-logo">
          <img src={Img} alt="Pace Style" />
        </a>
      </div>
    </div>
  );
}

export default Logo;