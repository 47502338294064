import DocumentMeta from 'react-document-meta';
import Banner from '../../components/Banner';
import Features from '../../components/Features';
import Gallery from '../../components/Gallery';
import Footer from '../../components/Footer';
import './index.css';

function Home() {
  const meta = {
    title: 'Pace Style',
    description: 'Contact us for your are tailor-made website',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'pace,pacestyle,meta,document,html,tags,tailor-made'
      }
    }
  }

  return (
    <DocumentMeta {...meta}>
      <div className="wrap">
        <Banner />
        <Features />
        <Gallery />
        <Footer />
      </div>
    </DocumentMeta>
  );
}

export default Home;