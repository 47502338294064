import Mobile from './Mobile';
import Briefcase from './Briefcase';
import Team from './Team';
import Performance from './Performance';

const Icon = (props) => {
  switch(props.name) {
    case "mobile":
      return <Mobile {...props} />;
    case "briefcase":
      return <Briefcase {...props} />;
    case "team":
      return <Team {...props} />;
    case "performance":
      return <Performance {...props} />;
    default:
      return <div />;
  }
}

export default Icon;