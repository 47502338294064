import Card from '../Card';
import Logo from '../Logo';
import './index.css';

const Footer = () => {
  return (
    <section className="section-footer">
      <div className="u-sheet">
        <div className="u-cardbox">
          <Logo />
          <Card
            title = "Office Hours" 
            detail = "10:00am - 6:00pm (Mon – Fri)" 
          />
          <Card
            title = "Email" 
            email = "info@pace.style" 
          />
        </div>
        <div className="u-line"></div>
        <p>© 2022 Pace Style Limited.</p>
      </div>
    </section>
  );
}

export default Footer;