import './index.css';

const Banner = () => {
  return (
    <section className="section-banner">
      <div className="sheet u-sheet">
        <h3 className="u-title">Future innovations</h3>
        <h1>Build Your Amazing Website with us</h1>
        <p>The future is fast approaching, and a new era of digital innovation and disruption is here.</p>
      </div>
    </section>
  );
}

export default Banner;