import React from "react";

const SVG = ({
  style = {},
  fill = '#fff',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 60 60',
}) => (
  <svg 
    width={width} 
    height={height} 
    style={style} 
    viewBox={viewBox} 
    x="0px" 
    y="0px" 
    id="svg-ace2" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g>
    <circle cx="7" cy="43" r="2"></circle>
    <path d="M51,6c-0.552,0-1,0.447-1,1s0.448,1,1,1h1c0.552,0,1-0.447,1-1s-0.448-1-1-1H51z"></path>
    <path d="M44,6h-1c-0.552,0-1,0.447-1,1s0.448,1,1,1h1c0.552,0,1-0.447,1-1S44.552,6,44,6z"></path>
    <path d="M36,6h-1c-0.552,0-1,0.447-1,1s0.448,1,1,1h1c0.552,0,1-0.447,1-1S36.552,6,36,6z"></path>
    <path d="M32,6h-1c-0.552,0-1,0.447-1,1s0.448,1,1,1h1c0.552,0,1-0.447,1-1S32.552,6,32,6z"></path>
    <path d="M55,6c-0.552,0-1,0.447-1,1s0.448,1,1,1h1c0.552,0,1-0.447,1-1s-0.448-1-1-1H55z"></path>
    <path d="M47,6c-0.552,0-1,0.447-1,1s0.448,1,1,1h1c0.552,0,1-0.447,1-1s-0.448-1-1-1H47z"></path>
    <path d="M40,6h-1c-0.552,0-1,0.447-1,1s0.448,1,1,1h1c0.552,0,1-0.447,1-1S40.552,6,40,6z"></path>
    <path d="M59,20.957c-0.552,0-1,0.447-1,1v0.993c0,0.553,0.448,1,1,1s1-0.447,1-1v-0.993C60,21.404,59.552,20.957,59,20.957z"></path>
    <path d="M59,24.932c-0.552,0-1,0.447-1,1v0.792c-0.012-0.008-0.025-0.013-0.037-0.021c-0.178-0.114-0.363-0.214-0.555-0.302   c-0.058-0.026-0.114-0.053-0.173-0.077c-0.044-0.018-0.085-0.041-0.129-0.057c-0.036-0.013-0.072-0.008-0.108-0.017   C56.554,26.1,56.088,26,55.595,26h-1.546h-0.913h-2.737h-0.912h-2.738h-0.912H43.1h-0.913H39.45h-0.913H35.8h-0.913H32.15h-0.913   h-2.382c-0.173-0.296-0.487-0.5-0.856-0.5c-0.366,0-0.673,0.207-0.847,0.5H4.405C1.977,26,0,27.976,0,30.405v25.189   C0,58.024,1.977,60,4.405,60h51.189C58.023,60,60,58.024,60,55.595V30.405v-0.5c0-0.152-0.04-0.293-0.101-0.422   c-0.125-0.585-0.369-1.124-0.701-1.598C59.652,27.791,60,27.407,60,26.925v-0.993C60,25.379,59.552,24.932,59,24.932z M7,47   c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S9.206,47,7,47z M52,58H14V28h13h1.5h2.737h0.913h2.737H35.8h2.737h0.913h2.737H43.1   h2.737h0.912h2.738h0.912H52V58z M57,47c0,0.552-0.447,1-1,1s-1-0.448-1-1v-1c0-0.552,0.447-1,1-1s1,0.448,1,1V47z M57,43   c0,0.552-0.447,1-1,1s-1-0.448-1-1v-4c0-0.552,0.447-1,1-1s1,0.448,1,1V43z"></path>
    <path d="M34.78,2h0.994c0.552,0,1-0.447,1-1s-0.448-1-1-1H34.78c-0.552,0-1,0.447-1,1S34.228,2,34.78,2z"></path>
    <path d="M57.82,3.492c0.159,0.387,0.532,0.62,0.926,0.62c0.126,0,0.255-0.023,0.379-0.075c0.511-0.209,0.755-0.793,0.546-1.305   c-0.163-0.396-0.383-0.768-0.654-1.102C58.668,1.2,58.039,1.136,57.61,1.484c-0.429,0.348-0.495,0.978-0.147,1.406   C57.612,3.073,57.732,3.275,57.82,3.492z"></path>
    <path d="M42.729,2h0.994c0.552,0,1-0.447,1-1s-0.448-1-1-1h-0.994c-0.552,0-1,0.447-1,1S42.177,2,42.729,2z"></path>
    <path d="M50.678,2h0.993c0.552,0,1-0.447,1-1s-0.448-1-1-1h-0.993c-0.552,0-1,0.447-1,1S50.125,2,50.678,2z"></path>
    <path d="M38.755,2h0.993c0.552,0,1-0.447,1-1s-0.448-1-1-1h-0.993c-0.552,0-1,0.447-1,1S38.203,2,38.755,2z"></path>
    <path d="M54.652,2h0.994c0.552,0,0.975-0.447,0.975-1s-0.473-1-1.025-1h-0.943c-0.552,0-1,0.447-1,1S54.1,2,54.652,2z"></path>
    <path d="M46.703,2h0.994c0.552,0,1-0.447,1-1s-0.448-1-1-1h-0.994c-0.552,0-1,0.447-1,1S46.151,2,46.703,2z"></path>
    <path d="M59,9.034c-0.552,0-1,0.447-1,1v0.994c0,0.553,0.448,1,1,1s1-0.447,1-1v-0.994C60,9.481,59.552,9.034,59,9.034z"></path>
    <path d="M59,5.061c-0.552,0-1,0.447-1,1v0.993c0,0.553,0.448,1,1,1s1-0.447,1-1V6.061C60,5.508,59.552,5.061,59,5.061z"></path>
    <path d="M59,16.983c-0.552,0-1,0.447-1,1v0.993c0,0.553,0.448,1,1,1s1-0.447,1-1v-0.993C60,17.431,59.552,16.983,59,16.983z"></path>
    <path d="M59,13.009c-0.552,0-1,0.447-1,1v0.993c0,0.553,0.448,1,1,1s1-0.447,1-1v-0.993C60,13.456,59.552,13.009,59,13.009z"></path>
    <path d="M28,24.499c0.552,0,1-0.447,1-1v-0.993c0-0.553-0.448-1-1-1s-1,0.447-1,1v0.993C27,24.052,27.448,24.499,28,24.499z"></path>
    <path d="M28,12.576c0.552,0,1-0.447,1-1v-0.993c0-0.553-0.448-1-1-1s-1,0.447-1,1v0.993C27,12.129,27.448,12.576,28,12.576z"></path>
    <path d="M28,8.602c0.552,0,1-0.447,1-1V6.608c0-0.553-0.448-1-1-1s-1,0.447-1,1v0.993C27,8.154,27.448,8.602,28,8.602z"></path>
    <path d="M27.862,4.608c0.076,0.018,0.152,0.025,0.227,0.025c0.455,0,0.866-0.313,0.973-0.774c0.053-0.228,0.139-0.445,0.255-0.648   c0.275-0.479,0.109-1.091-0.37-1.365c-0.479-0.275-1.091-0.108-1.365,0.369c-0.214,0.374-0.372,0.775-0.469,1.193   C26.989,3.946,27.324,4.483,27.862,4.608z"></path><path d="M30.809,2.052c0.058,0,0.116-0.005,0.175-0.016C31.121,2.013,31.262,2,31.405,2H31.8c0.552,0,1-0.447,1-1s-0.448-1-1-1   h-0.395c-0.259,0-0.518,0.022-0.77,0.067c-0.544,0.096-0.907,0.614-0.811,1.158C29.911,1.711,30.333,2.052,30.809,2.052z"></path><path d="M28,20.525c0.552,0,1-0.447,1-1v-0.994c0-0.553-0.448-1-1-1s-1,0.447-1,1v0.994C27,20.078,27.448,20.525,28,20.525z"></path><path d="M28,16.551c0.552,0,1-0.447,1-1v-0.994c0-0.553-0.448-1-1-1s-1,0.447-1,1v0.994C27,16.104,27.448,16.551,28,16.551z"></path>
    <path d="M4,22c0.552,0,1-0.447,1-1c0-6.065,4.935-11,11-11h2.586l-4.293,4.293c-0.391,0.391-0.391,1.023,0,1.414   C14.488,15.902,14.744,16,15,16s0.512-0.098,0.707-0.293l5.999-5.999c0.093-0.092,0.166-0.203,0.217-0.326   c0.101-0.244,0.101-0.52,0-0.764c-0.051-0.123-0.124-0.234-0.217-0.326l-5.999-5.999c-0.391-0.391-1.023-0.391-1.414,0   s-0.391,1.023,0,1.414L18.586,8H16C8.832,8,3,13.832,3,21C3,21.553,3.448,22,4,22z"></path>
  </g>
  </svg>
)

export default SVG;