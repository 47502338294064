import Icon from '../Icon';
import './index.css';

const Card = (props) => {
  const {icon, title, detail, email} = props

  let cardIcon = ""
  if (icon) {
    cardIcon = <span><Icon name={icon} width={64} height={64} /></span>
  }

  let emailLink = ""
  if (email) {
    let mailto = 'mailto: ' + email
    emailLink = <a href={mailto}>{email}</a>
  }

  return (
    <div className="u-card">
      <div className="u-card-wrap">
        {cardIcon}
        <h4 className="u-title">{title}</h4>
        <p>{detail}{emailLink}</p>
      </div>
    </div>
  );
}

export default Card;