import Card from '../Card';
import './index.css';

const Features = () => {
  return (
    <section className="section-features">
      <div className="u-sheet">
        <h1 className="u-title">Web Design & E-commerce</h1>
        <div className="u-cardbox">
          <Card 
            icon = 'mobile'
            title = 'Responsive' 
            detail = 'Responsive Web Design is an automate resize to make it look good on all devices' 
          />
          <Card 
            icon = 'briefcase'
            title = 'CMS' 
            detail = 'CMS help user to manage the creation and modification content.' 
          />
          <Card 
            icon = 'team'
            title = 'SOCIAL MEDIA' 
            detail = 'Connecting your website to social media channels bring a huge advantages.' 
          />
          <Card 
            icon = 'performance'
            title = 'SEO' 
            detail = 'SEO can be affected directly position in the search rankings.' 
          />
        </div>
      </div>
    </section>
  );
}

export default Features;